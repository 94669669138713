import { ref, onBeforeUnmount, watch, type Ref } from 'vue';

/**
 * A composable function that sets up a MutationObserver to monitor changes an observed HTML Element Ref.
 * It detects if the container or its children are tampered with, such as being hidden or removed by adblocker.
 *
 * @returns {Object} An object containing:
 *   - `tamperedWith`: A ref boolean indicating if the container or its children have been tampered with.
 *   - `removedNodes`: A ref array of removed HTMLElement nodes (or whole container).
 */
export function useTamperObserver(element: Ref<HTMLElement | null>) {
	const tamperedWith = ref(false);
	const removedNodes = ref<HTMLElement[]>([]);
	let observer: MutationObserver | null = null;

	/**
	 *  Helper function to detect if an element is visually hidden
	 * */
	function isStyleHidden(target: HTMLElement): boolean {
		const style = window.getComputedStyle(target);
		return (
			style.display === 'none' ||
			style.opacity === '0' ||
			style.visibility === 'hidden' ||
			style.height === '0px' ||
			style.width === '0px' ||
			style.position === 'absolute'
		);
	}

	/**
	 *  Check for attribute mutations that may indicate ad blocker activity
	 * */
	function isAdblockerMutation(mutation: MutationRecord): boolean {
		if (mutation.type === 'attributes') {
			const target = mutation.target as HTMLElement;
			if (mutation.attributeName === 'style') {
				return isStyleHidden(target);
			}
			if (mutation.attributeName === 'class') {
				const className = target.className || '';
				return /hidden|invisible|disabled/i.test(className);
			}
		}

		// Check for childList mutations, such as removed elements
		if (mutation.type === 'childList') {
			// Detect if the container itself or its children were removed
			if (element.value && !document.body.contains(element.value)) {
				removedNodes.value = [element.value];
				return true;
			}

			// Check only for removed element nodes (not text nodes or comments)
			const filteredRemovedNodes = Array.from(mutation.removedNodes).filter(
				node => node.nodeType === Node.ELEMENT_NODE
			) as HTMLElement[];

			if (filteredRemovedNodes.length > 0) {
				removedNodes.value = filteredRemovedNodes;
				return true;
			}
		}

		return false;
	}

	function setupObserver(): void {
		const observedElement = element.value;
		if (!observedElement) return;

		// Disconnect existing observer if any
		if (observer) {
			observer.disconnect();
		}

		observer = new MutationObserver(mutations => {
			tamperedWith.value = mutations.some(mutation => isAdblockerMutation(mutation));
		});

		observer.observe(observedElement, {
			attributes: true,
			attributeFilter: ['style', 'class'],
			childList: true,
			subtree: true,
		});

		if (observedElement.parentElement) {
			observer.observe(observedElement.parentElement, {
				childList: true,
			});
		}
	}

	watch(
		element,
		(newElement, oldElement) => {
			if (newElement !== oldElement) {
				setupObserver();
			}
		},
		{ immediate: true }
	);

	onBeforeUnmount(() => {
		if (observer) {
			observer.disconnect();
		}
	});

	return {
		tamperedWith,
		removedNodes,
	};
}
