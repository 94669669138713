
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PagesList from '@/components/nav/static-pages/PagesList.vue';
import { COUNTRY_EXCEPTIONS, WEB_LOCALES } from '@/constants/web-locales.constant';

import type { WebLocale } from '@/enums/web-locale';
import { StaticPage, StaticRequestOptions, fetchStaticPages } from '@/helpers/static-page-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { PageListItem } from '@/components/nav/static-pages/types';
import { trackPopularExp } from '@/components/experiments/PopularPageExp/tracking';

const Language = namespace('language');

@Component({
	name: 'SettingsButton',
})
export default class SettingsButton extends Vue {
	@Language.Getter country: string;
	@Language.Getter language: string;
	@Language.State webLocale: string;

	staticPages: StaticPage[] = [];
	loading: boolean = false;

	@Watch('language')
	onLanguageChange() {
		this.fetchSettingItems();
	}

	/**
	 * fetch settings items based on locale and language
	 */
	async fetchSettingItems(): Promise<void> {
		const country: WebLocale = this.country.toLowerCase() as WebLocale;
		const locale = WEB_LOCALES[country] || WEB_LOCALES[COUNTRY_EXCEPTIONS[country]];

		const options: Partial<StaticRequestOptions> = {
			language: this.language,
		};

		this.staticPages = await fetchStaticPages(locale, options);
	}

	get formattedPagesDetails(): PageListItem[] {
		const staticPagesConfigs = this.staticPages
			.filter(page => !['STATIC_FAQ', 'STATIC_TERMS', 'STATIC_PRIVACY_POLICY'].includes(page.technical_name))
			.map(page => {
				const { full_path, translation } = page;

				return {
					fullPath: full_path,
					label: translation,
					external: false,
				};
			});

		staticPagesConfigs.splice(3, 0, {
			fullPath: 'https://media.justwatch.com',
			external: true,
			label: this.$t('WEBAPP_JUSTWATCHMEDIA') as string,
		});

		return [
			...staticPagesConfigs,
			{
				fullPath: `${this.webLocale}/faq`,
				external: false,
				label: this.$t('WEBAPP_STATIC_FAQ') as string,
			},
			{
				fullPath: `${this.webLocale}/terms`,
				external: false,
				label: this.$t('WEBAPP_PAGE_NAME_TRANSLATABLE_STATIC_TERMS') as string,
			},
			{
				fullPath: `${this.webLocale}/privacy-policy`,
				external: false,
				label: this.$t('WEBAPP_STATIC_PRIVACY_POLICY') as string,
			},
		];
	}

	async openMenu(event: Event) {
		this.loading = true;
		TrackingHelper.trackEvent('userinteraction', {
			action: 'open_dropdown_menu',
		});
		//POPULAR_EXP
		trackPopularExp({ action: 'click', label: 'dropdown_menu_opened' });
		//POPULAR_EXP

		if (!this.staticPages.length) {
			await this.fetchSettingItems();
		}
		const { popoverController } = await import('@/ionic.loader');

		const popover = await popoverController.create({
			cssClass: 'static-pages-popover',
			component: PagesList,
			componentProps: { parent: this, propsData: { pages: this.formattedPagesDetails } },
			event,
			mode: 'md',
		});

		await popover.present();
		this.loading = false;

		await popover.onWillDismiss();
	}
}
