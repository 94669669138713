/**
 * @deprecated Search should be singular store.
 */

import { CollectionType } from '@/enums/collection-type';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

// STATE
const state = () => ({
	isLoading: false as boolean,
	query: '' as string,
	person_id: null as number | null,
	isShown: false as boolean,
});

type State = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<State, any> = {
	isSearchSuggesterOpen: state => {
		return state.isShown;
	},
	isLoading: state => {
		return state.isLoading;
	},
	query: state => {
		return state.query;
	},
};

// ACTIONS
const actions: ActionTree<State, any> = {
	reset: async ({ commit }) => {
		commit('RESET');
	},

	setSearchSuggesterVisibility({ commit, rootGetters }, value: boolean) {
		const referralModuleContext = rootGetters['titleDetails/refferalModuleContext'];
		const contexts = referralModuleContext ? [referralModuleContext] : [];
		const payload = { contexts, value };
		commit('SET_SEARCH_SUGGESTER_VISIBILITY', payload);
	},

	search: async ({ commit }, { searchQuery }: { searchQuery: string }) => {
		// reset if the search query is empty
		if (!searchQuery || searchQuery.length === 0) {
			commit('RESET');
		}

		commit('SET_QUERY', searchQuery);
	},

	/**
	 * routing to search page along with setting filters.
	 */
	goToSearch({ dispatch }, { searchString, personId }: { searchString: string; personId?: number }) {
		dispatch('user/saveSearchTerm', searchString, { root: true });
		const routeQuery = {
			q: searchString,
			person_id: personId,
		};

		dispatch('filter/updateRouterState', { collectionType: CollectionType.SEARCH, routeQuery }, { root: true });
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {
	RESET: state => {
		state.query = '';
		// state.isShown = false;
	},

	SET_QUERY(state, q: string) {
		state.query = q;
	},
	SET_PERSON_ID(state, personId: number) {
		state.person_id = personId;
	},
	SET_SEARCH_SUGGESTER_VISIBILITY(state, payload) {
		const { contexts, value } = payload;
		if (state.isShown !== value) {
			state.isShown = value;
			const action = value ? 'open_suggester' : 'close_suggester';
			TrackingHelper.trackEvent(
				'userinteraction',
				{
					action,
				},
				contexts
			);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
