import { getVm } from '@/helpers/vm-helper';
import PopularJWTVExp, { PopularJWTVVariants, PopularJWTVName } from '.';
import { ExperimentTrackEventOptions } from '../Core/types';
import { CollectionType } from '@/enums/collection-type';

export const trackPopularExp = (
	TrackingOptions: ExperimentTrackEventOptions<PopularJWTVVariants>,
	lastRoute = false
) => {
	const vm = getVm();
	const activeVariant = vm?.$store.getters['experiment/activeVariantsWithControlGroup'][PopularJWTVName] ?? null;
	const isUserLoggedIN = !!vm?.$store.getters['user/isLoggedIn'];
	const lastRouteName = vm?.$store.state.routing.lastRoute?.name;
	const popularFilters = vm?.$store.getters['filter/filters'](CollectionType.POPULAR);

	const routeName = lastRoute ? lastRouteName : vm?.$route.name;
	if (activeVariant && !isUserLoggedIN && isRouteNamePopularExp(routeName) && popularFilters.providers.length === 0) {
		PopularJWTVExp.trackEvent({
			property: routeName === 'app.titles.popular.show' ? 'show' : 'movie',
			...TrackingOptions,
		});
	}
};

export function isRouteNamePopularExp(routeName: string) {
	return ['app.titles.popular.movie', 'app.titles.popular.show'].includes(routeName);
}
