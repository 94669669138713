import { SnowplowContext, TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getVm } from '@/helpers/vm-helper';

// `import type` so this stays a dynamic import and doesn't mess up `getCtr`
import type { modalController } from '@/ionic.loader';

type ModalController = typeof modalController;
let mCtr: ModalController | null = null;

const getCtr = async () => {
	if (!mCtr) {
		const { modalController } = await import('@/ionic.loader');
		mCtr = modalController;
		return mCtr;
	}
	return mCtr;
};

export { getCtr };

export const ModalHelper = {
	openModal: async (
		component: any,
		componentProps = {},
		options = {},
		location = '', // For tracking.
		customModalOpenEvent = false,
		additinalContexts: SnowplowContext[] = [],
		onCloseCallback?: Function,
		init?: Function,
		trackingAdditionalInfo?: TrackingProviderPropertiesInterface
	) => {
		if (init) {
			init();
		}

		const modalController = await getCtr();

		const opts = {
			component,
			componentProps: { parent: getVm(), propsData: { ...componentProps, location } },
			...options,
		};

		const modal = await modalController.create(opts);
		await modal.present();
		let componentName = component?.extendOptions?.name || component?.__name;
		if (!componentName) {
			componentName = (await component())?.default?.extendOptions?.name;
		}

		const trackingInfo: TrackingProviderPropertiesInterface = { action: 'modal_opened', ...trackingAdditionalInfo };

		switch (componentName) {
			case 'AccountModal':
				if (location !== '') trackingInfo.property = location;
			case 'SettingsModal':
				trackingInfo.label = 'user_account';
				break;
			case 'MarkAsSeenModal':
				trackingInfo.label = 'tv_show_tracking';
				break;
			case 'MarkAsSeenModalGraphql':
				trackingInfo.label = 'tv_show_tracking';
				break;
			case 'CancelProModal':
				if (location !== '') trackingInfo.property = location;
				trackingInfo.label = 'FreemiumCancellationModal';
				break;
			case 'SearchTitlesToAddModal':
				trackingInfo.label = 'SearchTitlesToAddLists';
				trackingInfo.property = 'lists';
				break;
			case 'AddToListModal':
			case 'CreateCustomListModal':
			case 'ImportIMDBListModal':
			case 'RenameListModal':
				trackingInfo.label = componentName;
				trackingInfo.property = 'lists';
				break;
			case 'ConsentModal':
				trackingInfo.nonInteraction = true;
			default:
				trackingInfo.label = componentName;
				break;
		}

		const contexts: SnowplowContext[] = getVm().$store.getters['titleDetails/refferalModuleContext']
			? [getVm().$store.getters['titleDetails/refferalModuleContext']]
			: [];

		if (!customModalOpenEvent) {
			TrackingHelper.trackEvent('userinteraction', trackingInfo, contexts.concat(additinalContexts));
		}

		await modal.onWillDismiss();
		// INP case: artificially give the JS thread time to breathe by adding a re-render.
		await new Promise(resolve => setTimeout(resolve, 0));

		const modalData = await modal.onDidDismiss();

		if (onCloseCallback) {
			onCloseCallback();
		}

		if (!customModalOpenEvent) {
			const { getLastProView } = await import('@/helpers/pro-web-helper');
			TrackingHelper.trackEvent(
				'userinteraction',
				{
					action: 'modal_closed',
					label: trackingInfo.label,
					property:
						componentName === 'ProModal'
							? getLastProView() || undefined
							: componentName === 'CancelProModal'
							? 'SettingsProSubscription'
							: trackingInfo.property,
					nonInteraction: componentName === 'ConsentModal' ? true : undefined,
				},
				contexts.concat(additinalContexts)
			);
		}

		return modalData.data;
	},

	async close(id?: string): Promise<void> {
		if (mCtr == null) return;

		// When the modal force closes because of navigation it throws an
		// "overlay does not exist" error which is harmless
		mCtr?.dismiss({}, '', id).catch(() => {});
	},
};
