// List: https://docs.google.com/spreadsheets/d/10H6CRoCg4ZltW2sHnaDItYs4S0hc4qELMrp_oB4ieqg/edit?gid=0#gid=0

const excludedPackages = [
	352, 1957, 569, 3, 562, 1771, 192, 33, 349, 1764, 35, 1993, 40, 456, 61, 58, 224, 1862, 641, 2237, 2149, 1948,
];

// countries with New signups lower than avg play rate === 0  - 24.01.24
// prettier-ignore
export const disableATVAffiliation = [ "ID", "PH", "IN", "ZA", "EG", "MY", "TH", "UA", "BG", "VN", "QA", "HN", "PA", "EE", "AZ", "BO", "MD", "NI", "MU", "AM", "TT", "GH", "ZW", "NA", "BW", "AG", "KY", "CV", "MO", "LA" ];

// YouTube DACH region exceptions (https://justwatch.slack.com/archives/C0UH2TTB6/p1733747644335389?thread_ts=1733733752.284639&cid=C0UH2TTB6)
// If DACH region and one of the listed titles, include YouTube in the BuyBox
const ytCountryExceptions = ['DE', 'AT', 'CH'];
const ytJwEntityIdExceptions = ['tm1389634', 'tm1314896']; // Transformers One, Smile 2, Gladiator II

export function excludePackages(isLoggedIn: boolean, entityId: string, country: string) {
	const withYtExclusion = ytJwEntityIdExceptions.includes(entityId) && ytCountryExceptions.includes(country);

	return (
		excludedPackages
			.filter(packageId => !withYtExclusion || packageId !== 192)
			// If user is not logged in, exclude Crunchyroll and Tubi packages
			.concat(!isLoggedIn ? [283, 73] : [])
	);
}
