import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=POPULAR_JWTV_EXP_2:control
// ?preferred_variant=POPULAR_JWTV_EXP_2:variant_1
// ?preferred_variant=POPULAR_JWTV_EXP_2:variant_2

// ?remove_preferred_experiment=POPULAR_EXP

export const PopularJWTVName = 'POPULAR_JWTV_EXP_2' as const;

export type PopularJWTVVariants = ToVariants<typeof PopularJWTVVariants>;

export const PopularJWTVVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
} as const;

export default generate(PopularJWTVName, PopularJWTVVariants);
