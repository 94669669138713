import { TitleDetail } from '@/interfaces/title-details-graphql';

export function getTitleGaps(title: TitleDetail) {
	const _title = title.content.title;
	const backdrop = title.content.backdrops;
	const poster = title.content.posterUrl;
	const releaseYear = title.content.originalReleaseYear;
	const synopsis = title.content.shortDescription;

	return {
		title: !!_title,
		synopsis: !!synopsis,
		releaseYear: !!releaseYear,
		poster: !!poster,
		backdrop: backdrop.length > 0,
	} as const;
}
