import { MonetizationType } from '@/@types/graphql-types';
import { BuyBoxFilterOptions } from '@/stores/user.store';

export const sortedPackageIds = {
	'8': {
		free_trial: 0,
		price: 10.99,
		clearname: 'Netflix',
	},
	'9': {
		free_trial: 30,
		price: 8.99,
		clearname: 'Prime Video',
	},
	'11': {
		free_trial: 7,
		price: 11.99,
		clearname: 'MUBI',
	},
	'29': {
		free_trial: 7,
		price: 14,
		clearname: 'Sky Go',
	},
	'35': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Rakuten TV',
	},
	'39': {
		free_trial: 7,
		price: 6.99,
		clearname: 'Now TV',
	},
	'41': {
		free_trial: 0,
		price: 5.99,
		clearname: 'ITVX',
	},
	'76': {
		free_trial: 0,
		price: 14.99,
		clearname: 'Viaplay',
	},
	'87': {
		free_trial: 14,
		price: 4.99,
		clearname: 'Acorn TV',
	},
	'99': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Shudder',
	},
	'100': {
		free_trial: 0,
		price: 7.99,
		clearname: 'GuideDoc',
	},
	'103': {
		free_trial: 7,
		price: 3.99,
		clearname: 'Channel 4',
	},
	'119': {
		free_trial: 3,
		price: 8.99,
		clearname: 'Amazon Prime Video',
	},
	'122': {
		free_trial: 0,
		price: 5.99,
		clearname: 'Hotstar',
	},
	'151': {
		free_trial: 7,
		price: 5.99,
		clearname: 'BritBox',
	},
	'175': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Netflix Kids',
	},
	'188': {
		free_trial: 0,
		price: 12,
		clearname: 'YouTube Premium',
	},
	'189': {
		free_trial: 0,
		price: 6.99,
		clearname: 'Curzon Home Cinema',
	},
	'190': {
		free_trial: 0,
		price: 3.95,
		clearname: 'Curiosity Stream',
	},
	'192': {
		free_trial: 0,
		price: 11.99,
		clearname: 'YouTube',
	},
	'194': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Starz Play Amazon Channel',
	},
	'197': {
		free_trial: 0,
		price: 5.99,
		clearname: 'BritBox Amazon Channel',
	},
	'199': {
		free_trial: 7,
		price: 3.49,
		clearname: 'Fandor Amazon Channel',
	},
	'201': {
		free_trial: 0,
		price: 10.99,
		clearname: 'MUBI Amazon Channel',
	},
	'204': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Shudder Amazon Channel',
	},
	'205': {
		free_trial: 7,
		price: 5.99,
		clearname: 'Sundance Now Amazon Channel',
	},
	'223': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Hayu',
	},
	'224': {
		free_trial: 0,
		price: 4.99,
		clearname: 'BFI Player',
	},
	'255': {
		free_trial: 0,
		price: 19.99,
		clearname: 'Yupp TV',
	},
	'283': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Crunchyroll',
	},
	'287': {
		free_trial: 30,
		price: 4.99,
		clearname: 'BFI Player Amazon Channel',
	},
	'296': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Hayu Amazon Channel',
	},
	'337': {
		free_trial: 0,
		price: 7.99,
		clearname: 'Disney Plus',
	},
	'350': {
		free_trial: 7,
		price: 8.99,
		clearname: 'Apple TV Plus',
	},
	'432': {
		free_trial: 30,
		price: 5.99,
		clearname: 'Flix Premiere',
	},
	'444': {
		free_trial: 3,
		price: 7.28,
		clearname: 'Dekkoo',
	},
	'475': {
		free_trial: 3,
		price: 3.99,
		clearname: 'DOCSVILLE',
	},
	'510': {
		free_trial: 0,
		price: 6.99,
		clearname: 'Discovery Plus',
	},
	'529': {
		free_trial: 14,
		price: 4.99,
		clearname: 'ARROW',
	},
	'531': {
		free_trial: 7,
		price: 6.99,
		clearname: 'Paramount Plus',
	},
	'547': {
		free_trial: 0,
		price: 4.33,
		clearname: 'WOW Presents Plus',
	},
	'554': {
		free_trial: 0,
		price: 7.99,
		clearname: 'BroadwayHD',
	},
	'567': {
		free_trial: 0,
		price: 5.99,
		clearname: 'True Story',
	},
	'569': {
		free_trial: 0,
		price: 6,
		clearname: 'DocAlliance Films',
	},
	'582': {
		free_trial: 7,
		price: 6.99,
		clearname: 'Paramount Plus Amazon Channel',
	},
	'584': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Discovery Plus Amazon Channel',
	},
	'588': {
		free_trial: 7,
		price: 4.49,
		clearname: 'MGM Amazon Channel',
	},
	'591': {
		free_trial: 7,
		price: 6.99,
		clearname: 'Now TV Cinema',
	},
	'595': {
		free_trial: 7,
		price: 3.99,
		clearname: 'Eros Now Amazon Channel',
	},
	'596': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Arrow Video Amazon Channel',
	},
	'597': {
		free_trial: 7,
		price: 3.99,
		clearname: 'Full Moon Amazon Channel',
	},
	'598': {
		free_trial: 0,
		price: 3.99,
		clearname: 'ITV Amazon Channel',
	},
	'599': {
		free_trial: 7,
		price: 2.99,
		clearname: 'Pokémon Amazon Channel',
	},
	'600': {
		free_trial: 7,
		price: 2.99,
		clearname: 'Shout! Factory Amazon Channel',
	},
	'601': {
		free_trial: 7,
		price: 3.99,
		clearname: 'MotorTrend Amazon Channel',
	},
	'602': {
		free_trial: 7,
		price: 4.99,
		clearname: 'FilmBox Live Amazon Channel',
	},
	'603': {
		free_trial: 7,
		price: 3.95,
		clearname: 'CuriosityStream Amazon Channel',
	},
	'604': {
		free_trial: 7,
		price: 3.99,
		clearname: 'DocuBay Amazon Channel',
	},
	'607': {
		free_trial: 7,
		price: 4.99,
		clearname: 'OUTtv Amazon Channel',
	},
	'688': {
		free_trial: 7,
		price: 3.99,
		clearname: 'ShortsTV Amazon Channel',
	},
	'703': {
		free_trial: 0,
		price: 15,
		clearname: 'Sky Sports',
	},
	'1710': {
		free_trial: 7,
		price: 3.99,
		clearname: 'HistoryPlay Amazon Channel',
	},
	'1715': {
		free_trial: 0,
		price: 8.99,
		clearname: 'Shahid VIP',
	},
	'1744': {
		free_trial: 7,
		price: 5.99,
		clearname: 'Icon Film Amazon Channel',
	},
	'1745': {
		free_trial: 7,
		price: 6.99,
		clearname: 'Curzon Amazon Channel',
	},
	'1746': {
		free_trial: 7,
		price: 4.49,
		clearname: 'Hallmark TV Amazon Channel',
	},
	'1747': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Studiocanal Presents Amazon Channel',
	},
	'1757': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Realeyz Amazon Channel',
	},
	'1793': {
		free_trial: 7,
		price: 9.99,
		clearname: 'Klassiki',
	},
	'1795': {
		free_trial: 0,
		price: 14.99,
		clearname: 'Viaplay Sports',
	},
	'1796': {
		free_trial: 0,
		price: 4.99,
		clearname: 'Netflix basic with Ads',
	},
	'1835': {
		free_trial: 0,
		price: 6.99,
		clearname: 'Discovery Plus Sport Amazon Channel',
	},
	'1853': {
		free_trial: 7,
		price: 6.99,
		clearname: 'Paramount Plus Apple TV Channel',
	},
	'1940': {
		free_trial: 0,
		price: 14.99,
		clearname: 'Viaplay Sport Amazon Channel',
	},
	'1968': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Crunchyroll Amazon Channel',
	},
	'2034': {
		free_trial: 7,
		price: 0,
		clearname: 'Acorn TV Apple TV',
	},
	'2041': {
		free_trial: 7,
		price: 4.99,
		clearname: 'BFI Player Apple TV Channel',
	},
	'2060': {
		free_trial: 7,
		price: 0,
		clearname: 'CuriosityStream Apple TV Channel',
	},
	'2063': {
		free_trial: 7,
		price: 4.99,
		clearname: 'Crime Plus Investigation Play Amazon Channel',
	},
	'2068': {
		free_trial: 7,
		price: 0,
		clearname: 'Tastemade Apple TV Channel',
	},
	'2100': {
		free_trial: 0,
		price: 5.99,
		clearname: 'Amazon Prime Video with Ads',
	},
} as const;

export const buyboxFilterByMonetizationType = {
	[BuyBoxFilterOptions.SUBSCRIPTION]: [MonetizationType.Flatrate, MonetizationType.FlatrateAndBuy],
	[BuyBoxFilterOptions.FREE]: [MonetizationType.Free, MonetizationType.Ads, MonetizationType.Fast],
	[BuyBoxFilterOptions.FAST_TV]: [MonetizationType.Fast],
	[BuyBoxFilterOptions.RENT]: [MonetizationType.Rent],
	[BuyBoxFilterOptions.BUY]: [MonetizationType.Buy],
	[BuyBoxFilterOptions.CINEMA]: [MonetizationType.Cinema],
};
