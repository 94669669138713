import { UpcomingReleaseLabel } from '@/@types/graphql-types';

export type UpcomingReleaseForSpinningText = {
	date: string;
	providers: string;
	label: UpcomingReleaseLabel;
};

// prettier-ignore
export const allowedLanguagesUpcomingReleases = [
	'en','fr','es','it','pt','tr','de','bg','cs','fi','ko','pl','sv','hu',
	'az','el','ja','ro','ru','sr','uk','ur','ar',
];

export const upcomingReleasesOrdered = {
	[UpcomingReleaseLabel.Today]: 0,
	[UpcomingReleaseLabel.Tomorrow]: 1,
	[UpcomingReleaseLabel.Date]: 2,
	[UpcomingReleaseLabel.Month]: 3,
	[UpcomingReleaseLabel.Spring]: 4,
	[UpcomingReleaseLabel.Summer]: 5,
	[UpcomingReleaseLabel.Autumn]: 6,
	[UpcomingReleaseLabel.Winter]: 7,
	[UpcomingReleaseLabel.Year]: 8,
	[UpcomingReleaseLabel.ToBeAnnounced]: 9,
};

export function isSeason(label: UpcomingReleaseLabel) {
	return [
		UpcomingReleaseLabel.Autumn,
		UpcomingReleaseLabel.Winter,
		UpcomingReleaseLabel.Spring,
		UpcomingReleaseLabel.Summer,
	].includes(label);
}

export function getUpcomingLabelForFormattedDate(label: UpcomingReleaseLabel) {
	return [UpcomingReleaseLabel.Today, UpcomingReleaseLabel.Tomorrow].includes(label)
		? UpcomingReleaseLabel.Date
		: label;
}
