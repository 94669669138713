import type { Store } from 'vuex';

import { getAuthHeaders, getAxios } from '@/helpers/axios-helper';

import { LoginProviderByFirebaseProviderId } from '@/enums/login';
import { ModalHelper } from '@/helpers/modal-helper';
import type { SnowplowContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { SnowplowLoginContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { LoginMessageModal } from '@/lazy-load-components';

import { UserAccountEvents } from '@/enums/events';
import { AccountModes } from '@/enums/login';

import {
	EmailAuthProvider,
	getAuth,
	OAuthProvider,
	reauthenticateWithCredential,
	reauthenticateWithPopup,
	sendPasswordResetEmail,
} from 'firebase/auth';
import LocalForage from 'localforage';
import { captureMessageForSentry } from './sentry-helper';

type AuthConsents = {
	acceptedPrivacyPolicy: boolean;
	acceptedEmailAgreement: boolean;
	mode: string;
	location: string;
};

const AUTH_CONSENTS = 'AUTH_CONSENTS';

const reauthenticateSocialAuth = async (provider: string): Promise<boolean> => {
	try {
		const user = await currentUser();
		if (!user) return false;
		await reauthenticateWithPopup(user, new OAuthProvider(LoginProviderByFirebaseProviderId[provider]));
		return true;
	} catch (error) {
		return false;
	}
};

const logout = async (token: string | undefined, justwatchId: string | undefined) => {
	if (!token && !justwatchId) {
		console.warn('Trying to logout without jwId');
		return;
	}

	const headers = await getAuthHeaders(token, { ['DEVICE-ID']: justwatchId });
	return getAxios().post(`${JW_CONFIG.IDENTITY_URL}/sign_out`, {}, { headers });
};

const requestPasswordReset = async (payload: {
	email: string;
	url: string;
	handleCodeInApp: boolean;
}): Promise<any> => {
	return sendPasswordResetEmail(getAuth(), payload.email, {
		url: payload.url,
		handleCodeInApp: payload.handleCodeInApp,
	});
};

const sendCode = async (code: string, token: string): Promise<any> => {
	const headers = await getAuthHeaders(token);
	return getAxios().post(`${JW_CONFIG.USER_URL}/tv/connect`, { code }, { headers });
};

const openSuccessModal = (successType: 'logged-in' | 'login-failed' | 'sign-up-failed') => {
	try {
		ModalHelper.openModal(LoginMessageModal, { successType, closeDelay: 2000 });
	} catch (error: any) {
		captureMessageForSentry(
			'[Opening Login Message Modal]:failed',
			{
				error,
				where: '[user-helper.ts]: openSuccessModal()',
			},
			'error'
		);
	}
};

const authenticateUser = async (store: Store<any>) => {
	try {
		// If this succeeds, then it means the user is registered internally (meaning the operation is a login operation)
		await store.dispatch('user/verifyRegistration');
		// Login operation
		try {
			const authConsents: AuthConsents | null = await LocalForage.getItem(AUTH_CONSENTS);
			await store.dispatch('user/signInInternally');
			const contexts: SnowplowContext[] = [new SnowplowLoginContext(store.state.user.jwLoginId)];
			TrackingHelper.trackEvent(
				'user_account',
				{
					action: UserAccountEvents.LOGIN,
					property: authConsents?.location
						? `${store.state.user.info.loginProvider}_${authConsents.location}`
						: store.state.user.info.loginProvider,
					label: store.state.user.jwLoginId,
				},
				contexts
			);

			await LocalForage.removeItem(AUTH_CONSENTS);
			openSuccessModal('logged-in');
		} catch (err) {
			// If we can't sign in with Firebase SDK then we logout the user (jw-app state)
			const error: any = err;
			await store.dispatch('user/logout');

			TrackingHelper.trackEvent('user_account', {
				action: UserAccountEvents.LOGIN_ERROR,
				property: store.state.user.info.loginProvider,
				label: error,
			});
			captureMessageForSentry(
				'[Authentication]: Login failed',
				{
					error,
					where: '[user-helper.ts]: authenticateUser()',
				},
				'error',
				{ AuthFail: store.state.user.info.loginProvider }
			);
			openSuccessModal('login-failed');
		}
	} catch {
		// SIGN UP
		// User is not yet registered with our internal backend.
		return false;
	}

	return true;
};

const currentUser = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		const auth = getAuth();
		if (auth.currentUser) {
			resolve(auth.currentUser);
		}
		const unsubscribe = auth.onAuthStateChanged((user: any) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

const generateToken = async (handleLogout = true) => {
	try {
		const user = await currentUser();

		if (!user) {
			throw new Error('Error getting firebase user');
		}

		const token = await user.getIdToken();
		return token;
	} catch (error) {
		// no error caught, because it's already caught outside of generateToken().
	}
};

const reauthenticateUserWithPassword = async (password: string) => {
	try {
		const user = await currentUser();
		if (!user) return false;
		const credential = EmailAuthProvider.credential(user.email, password);
		await reauthenticateWithCredential(user, credential);
		return true;
	} catch (error) {
		return false;
	}
};

const signUpUser = async (store: Store<any>) => {
	try {
		await store.dispatch('user/registerInternally', false);
		const authConsents: AuthConsents | null = await LocalForage.getItem(AUTH_CONSENTS);
		if (authConsents) {
			const consents = store.getters['user/consents'];
			// If the user creates account with the SIGN_IN modal, we want to save the user consents as true and assume they gave consent
			await store.dispatch('user/saveSettings', {
				jw_consents: {
					...consents,
					emails: authConsents.mode === AccountModes.SIGN_UP ? authConsents.acceptedEmailAgreement : true,
					terms_and_conditions:
						authConsents.mode === AccountModes.SIGN_UP ? authConsents.acceptedPrivacyPolicy : true,
				},
			});

			const contexts: SnowplowContext[] = [new SnowplowLoginContext(store.state.user.jwLoginId)];
			TrackingHelper.trackEvent(
				'useraccount',
				{
					action: 'account_settings_submitted',
					label: 'data_protection',
					property: 'settings',
				},
				contexts
			);

			TrackingHelper.trackEvent(
				'useraccount',
				{
					action: 'account_settings_submitted',
					label: 'interest_emails',
					property: 'settings',
					value:
						authConsents.acceptedEmailAgreement == true || authConsents.mode === AccountModes.SIGN_IN
							? 1
							: 0,
				},
				contexts
			);

			TrackingHelper.trackEvent(
				'user_account',
				{
					action: UserAccountEvents.SIGNUP,
					property: authConsents?.location
						? `${store.state.user.info.loginProvider}_${authConsents.location}`
						: store.state.user.info.loginProvider,
					label: store.state.user.jwLoginId,
				},
				contexts
			);

			await LocalForage.removeItem(AUTH_CONSENTS);
		}

		openSuccessModal('logged-in');
	} catch (err) {
		const error: any = err;

		// If the registration in consumer-api fails, we just want to delete the user in firebase and then show a failure message.
		await store.dispatch('user/deleteAccountPermanently');
		openSuccessModal('sign-up-failed');
		TrackingHelper.trackEvent('user_account', {
			action: UserAccountEvents.SIGNUP_ERROR,
			property: store.state.user.info.loginProvider,
			label: error,
		});
	}
};

export {
	authenticateUser,
	currentUser,
	generateToken,
	logout,
	reauthenticateSocialAuth,
	reauthenticateUserWithPassword,
	requestPasswordReset,
	sendCode,
	signUpUser,
};
