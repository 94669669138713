import { render, staticRenderFns } from "./TitlePosterRibbonGraphqlLite.vue?vue&type=template&id=6419283a&scoped=true"
import script from "./TitlePosterRibbonGraphqlLite.vue?vue&type=script&setup=true&lang=ts"
export * from "./TitlePosterRibbonGraphqlLite.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TitlePosterRibbonGraphqlLite.vue?vue&type=style&index=0&id=6419283a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6419283a",
  null
  
)

export default component.exports