import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=QTV_CTA_EXP:control
// ?preferred_variant=QTV_CTA_EXP:variant_1
// ?preferred_variant=QTV_CTA_EXP:variant_2
// ?preferred_variant=QTV_CTA_EXP:variant_3

// ?remove_preferred_experiment=QTV_CTA_EXP

export const QtvCtaExpName = 'QTV_CTA_EXP' as const;

export type QtvCtaExpVariants = ToVariants<typeof QtvCtaExpVariants>;

export const QtvCtaExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
	VARIANT_4: 'variant_4',
	VARIANT_5: 'variant_5',
} as const;

export default generate(QtvCtaExpName, QtvCtaExpVariants);
