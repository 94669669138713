import Vue from 'vue';
import { Route } from 'vue-router';
import { ActionTree, Getter, GetterTree, Module, MutationTree } from 'vuex';

import { CollectionType } from '@/enums/collection-type';
import { TitleOrder } from '@/enums/title-order';
import type { WebLocale } from '@/enums/web-locale';
import { OfferMonetizationType, OfferPresentationType, TitleObjectType } from '@/interfaces/titles';

import { getCollectionTypeByNewPageType } from '@/helpers/graphql-model-helper';
import { arrayOfStringsAreEquals } from '@/helpers/object-helper';
import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { updateState } from '@/helpers/state-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getVm } from '@/helpers/vm-helper';
import Provider from '@/interfaces/provider';
import { ProviderType } from '@/components/filter-bar/filter-bar.types';
import { TabType } from '@/interfaces/inner-tabs';
import { TitleListLayout } from '@/enums/title-list-layout';
import { trackPopularExp } from '@/components/experiments/PopularPageExp/tracking';

export interface FilterValue {
	collectionType: CollectionType;
	filterName: FilterName;
	filterValue: any;
}

export interface FilterCollection {
	age_certifications: string[]; // "technicalName" of AgeCertification
	content_types: TitleObjectType[];
	providers_type: ProviderType | null;
	exclude_providers: string[];
	genres: string[]; // "short_code" from different genres
	exclude_genres: string[];
	event_date: Date | undefined;
	languages: string[] | null;
	min_price: number | null;
	min_runtime: number | null; // minutes
	matching_offers_only: boolean | null;
	max_price: number | null;
	max_runtime: number | null; // minutes
	monetization_types: OfferMonetizationType[];
	presentation_types: OfferPresentationType[];
	providers: string[]; // "shortName"s of different providers
	release_year_from: number | null;
	release_year_until: number | null;
	scoring_filter_types: Record<string, { min_scoring_value: number; max_scoring_value?: number }> | null;
	timeline_type: string | null;
	sort_by: TitleOrder | null;
	sort_asc: boolean | null;
	inner_tab?: TabType;
	list_layout?: TitleListLayout;
	production_countries: string[];
	exclude_production_countries: string[];
	is_upcoming: boolean;
	sorting_random_seed?: number;
	// SUBGENRES
	subgenres?: string[];
}

export type CountryFilters = {
	[collection in CollectionType]: FilterCollection;
};
export type FilterState = {
	[country: string]: CountryFilters;
};

export type FilterName = keyof FilterCollection;

export const getDefaultFilterCollection = (): FilterCollection => ({
	age_certifications: [], // "technicalName" of AgeCertification
	content_types: [],
	providers_type: null,
	exclude_providers: [],
	genres: [], // "short_code" from different genres
	exclude_genres: [],
	event_date: undefined,
	languages: null,
	min_price: null,
	min_runtime: null,
	matching_offers_only: null,
	max_price: null,
	max_runtime: null,
	monetization_types: [],
	presentation_types: [],
	providers: [], // "shortName"s of different providers
	release_year_from: null,
	release_year_until: null,
	scoring_filter_types: null,
	timeline_type: null,
	sort_by: null,
	sort_asc: null,
	production_countries: [],
	exclude_production_countries: [],
	is_upcoming: false,
	subgenres: [],
});

export const platformCollectionTypes: CollectionType[] = Object.values(CollectionType);

// will be populated in INIT_STATE
const state = () => ({} as FilterState);

type State = ReturnType<typeof state>;

const fillFilterCollection = (filterCollection?: FilterCollection): FilterCollection => ({
	...getDefaultFilterCollection(),
	...filterCollection,
});

const getDefaultCountryFilters = () =>
	Object.fromEntries(
		platformCollectionTypes.map(collectionType => [collectionType, getDefaultFilterCollection()])
	) as CountryFilters;

export const fillCountryFilters = (countryFilters?: CountryFilters) => {
	if (!countryFilters) {
		return getDefaultCountryFilters();
	}

	return Object.fromEntries(
		platformCollectionTypes.map(collectionType => [
			collectionType,
			fillFilterCollection(countryFilters[collectionType]),
		])
	) as CountryFilters;
};

function getFilterState(state: any, rootState: any, type: CollectionType): FilterCollection {
	return state[rootState.language.webLocale][type];
}

/**
 * this interface is used for URL param mapping from URL to filter states.
 */
export type CollectionStateUrlParams = Dictionary<string>;
/**
 * since the url query parameters can differ from the base collection state one, we have this monster of a type here.
 */
export type CollectionStateUrlQuery = Partial<
	Record<
		Exclude<
			| keyof FilterCollection
			| 'q'
			| 'person_id'
			| 'preferred_variant'
			| 'remove_preferred_experiment'
			| 'snowplow'
			| 'content_type',
			'content_types'
		>,
		string
	>
>;

// Since all subpages of New share the filter state, we don't need getters for any of the subpages but New itself
type CollectionTypeWithoutNewSubpages = Exclude<
	CollectionType,
	CollectionType.COMING_SOON | CollectionType.LEAVING_SOON | CollectionType.PRICEDROPS
>;

// GETTERS
const getters: GetterTree<State, any> & { [key in CollectionTypeWithoutNewSubpages]: Getter<State, any> } = {
	[CollectionType.GLOBAL]: (state, _getters, rootState) => getFilterState(state, rootState, CollectionType.GLOBAL),
	[CollectionType.SEENLIST]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.SEENLIST),
	[CollectionType.POPULAR]: (state, _getters, rootState) => getFilterState(state, rootState, CollectionType.POPULAR),
	[CollectionType.SUBGENRE]: (_, getters) => getters[CollectionType.POPULAR],
	[CollectionType.UPCOMING]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.UPCOMING),
	[CollectionType.NEW]: (state, _getters, rootState) => getFilterState(state, rootState, CollectionType.NEW),
	[CollectionType.SEARCH]: (state, _getters, rootState) => getFilterState(state, rootState, CollectionType.SEARCH),
	[CollectionType.OFFERS]: (state, _getters, rootState) => getFilterState(state, rootState, CollectionType.OFFERS),
	[CollectionType.TV_SHOW_TRACKING]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.TV_SHOW_TRACKING),
	[CollectionType.MY_LISTS]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.MY_LISTS),
	[CollectionType.PUBLIC_LISTS]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.PUBLIC_LISTS),
	[CollectionType.TITLELIST]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.TITLELIST),
	[CollectionType.SPORTS]: (state, _getters, rootState) => getFilterState(state, rootState, CollectionType.SPORTS),

	// Not really needed but just return the popular page filters
	[CollectionType.EDITORIAL]: (state, _getters, rootState) =>
		getFilterState(state, rootState, CollectionType.EDITORIAL),

	filters: (state, getters, rootState, rootGetters) => (collectionType: CollectionType) => {
		const webLocale = rootState.language.webLocale as WebLocale;
		return state[webLocale]?.[collectionType] ?? getDefaultFilterCollection();
	},

	/**
	 * Returns the whole current filter state for the current collection type
	 */
	currentFilters(state, getters, rootState, rootGetters): FilterCollection {
		const webLocale: string = rootState.language.webLocale;

		if (!state[webLocale]) {
			console.error('[filter.store] getter/currentFilters state not initialized for webLocale: ', webLocale);
		}

		const collectionType = rootGetters['routing/lastActiveCollectionType'] as CollectionType;

		// TODO Remove after fix https://sentry.justwatch.com/organizations/justwatch/issues/25003
		// This value should never be undefined, but it mysteriously is!
		if (!state[webLocale][collectionType]) {
			captureMessageForSentry(
				'Filter collection udefined',
				{
					where: '[filter.store.ts] currentFilters',
					webLocale,
					collectionType,
					filterState: JSON.stringify(state),
					collection: JSON.stringify(state[webLocale][collectionType]),
				},
				'error'
			);
		}

		return state[webLocale][collectionType];
	},

	/**
	 * returns a single top level value from the current collection type filter
	 *
	 * @TODO rename to 'currentFilterValue'
	 */
	currentFilter: (state, getters) => (filterName: FilterName) => {
		return getters.currentFilters[filterName];
	},
	selectedProvidersType: (state, getters, rootState, rootGetters) => {
		if (!getters.currentFilters.providers_type) {
			const collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;
			const isListsTab = [
				CollectionType.MY_LISTS,
				CollectionType.PUBLIC_LISTS,
				CollectionType.TV_SHOW_TRACKING,
			].includes(collectionType);

			if (isListsTab) {
				return 'all';
			}

			return rootGetters['user/isLoggedIn'] ? 'my_services' : 'all';
		}

		return getters.currentFilters.providers_type;
	},
	selectedProvidersByType:
		(state, getters, rootState, rootGetters) =>
		(type?: ProviderType): Provider[] => {
			const providerType = type !== undefined ? type : getters.selectedProvidersType;

			if (providerType === 'my_services') {
				return rootGetters['constant/providers'](true);
			}

			if (providerType === 'all') {
				return rootGetters['constant/providers'](false);
			}

			return rootGetters['constant/providersByType'][providerType];
		},
	// Note: Use this selector to get a list of provider short names needed for GraphQL query
	selectedProviders:
		(state, getters, rootState, rootGetters) =>
		(isSeoFilterBar: boolean): string[] => {
			const currentFilters = getters.currentFilters;

			if (isSeoFilterBar) {
				return currentFilters.providers?.length === 0
					? rootState.user.settings.providers
					: currentFilters.providers;
			}

			const providersType = getters.selectedProvidersType;

			if ((currentFilters.providers || []).length === 0 && providersType) {
				// Note: We need to send empty array instead of huge array of short names when all providers selected
				return providersType === 'all'
					? []
					: getters.selectedProvidersByType().map((p: Provider) => p.shortName);
			}

			return currentFilters.providers;
		},
};

// ACTIONS
const actions: ActionTree<State, any> = {
	setRange: (
		{ commit, dispatch, rootState, rootGetters },
		{
			rangeName,
			trackingKey,
			upper,
			lower,
			selectedState,
			updateRouteState = true,
			isPremiumFilter = false,
		}: {
			rangeName: 'price' | 'release_year' | 'runtime';
			trackingKey: string;
			upper: number | null;
			lower: number | null;
			selectedState?: 1 | 0;
			updateRouteState?: boolean;
			isPremiumFilter?: boolean;
		}
	) => {
		const collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;
		const filterKeys = {
			price: { lower: 'min_price', upper: 'max_price' },
			release_year: { lower: 'release_year_from', upper: 'release_year_until' },
			runtime: { lower: 'min_runtime', upper: 'max_runtime' },
		};
		const filterState = {
			[filterKeys[rangeName].lower]: lower,
			[filterKeys[rangeName].upper]: upper,
		};
		commit('SET_FILTERS', {
			webLocale: rootState.language.webLocale,
			collectionType,
			filterState,
		});
		updateRouteState && dispatch('updateRouterState');

		TrackingHelper.trackFilterToggleEvent(
			{
				label: rangeName,
				property: trackingKey,
				value: selectedState,
			},
			isPremiumFilter
		);
		//POPULAR_EXP
		trackPopularExp({ action: 'click', label: 'filter_toggled' });
		//POPULAR_EXP
	},

	setSorting: (
		{ commit, dispatch, rootState, rootGetters },
		{ sortBy, sortAsc }: { sortBy: string; sortAsc: boolean }
	) => {
		const collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;

		const filterState = {
			sort_by: sortBy,
			sort_asc: sortAsc,
		} as any;

		if (isRandomSort(sortBy, collectionType)) {
			filterState.sorting_random_seed = rootState.filter[rootState.language.webLocale][collectionType]
				.sorting_random_seed
				? rootState.filter[rootState.language.webLocale][collectionType].sorting_random_seed + 1
				: 1;
		}
		commit('SET_FILTERS', {
			webLocale: rootState.language.webLocale,
			collectionType: collectionType,
			filterState,
		});

		dispatch('updateRouterState');

		// Sort By options from GraphQL are Uppercase, but tracking expects lowercase TODO [Graphql] remove when get rid of REST
		TrackingHelper.trackEvent('userinteraction', {
			action: 'filter_toggled',
			label: 'sort_by',
			property: sortBy?.toLowerCase(),
		});
		//POPULAR_EXP
		trackPopularExp({ action: 'click', label: 'filter_toggled' });
		//POPULAR_EXP
	},

	setSortingGraphql: (
		{ commit, dispatch, rootState, rootGetters },
		{ sortBy, sortAsc }: { sortBy: TitleOrder; sortAsc: boolean }
	) => {
		const collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;

		if (isRandomSort(sortBy, collectionType)) {
		}

		commit('SET_FILTERS', {
			webLocale: rootState.language.webLocale,
		});

		dispatch('updateRouterState');

		TrackingHelper.trackEvent('userinteraction', {
			action: 'filter_toggled',
			label: 'sort_by',
			property: sortBy,
		});
	},

	resetFilter: (
		{ dispatch },
		{
			filterName,
			updateRouteState = true,
		}: { filterName: FilterName; resetAllCollections: boolean; updateRouteState?: boolean }
	) => {
		dispatch('setFilterValue', {
			filterValue: getDefaultFilterCollection()[filterName],
			filterName,
			updateRouteState,
		});
	},

	resetFilterForCollectionTypes: (
		{ dispatch },
		{ filterName, collectionTypes }: { filterName: FilterName; collectionTypes?: CollectionType[] }
	) => {
		dispatch('setFilterValueForCollectionTypes', {
			filterValue: getDefaultFilterCollection()[filterName],
			filterName,
			collectionTypes: collectionTypes ?? platformCollectionTypes,
		});
	},

	resetFilters: (
		{ commit, dispatch, rootGetters, rootState },
		{ filterNames, updateRouteState = true }: { filterNames: FilterName[]; updateRouteState: boolean }
	) => {
		filterNames.forEach((filterName: FilterName) => {
			const filterValue = getDefaultFilterCollection()[filterName];
			const activeCollectionType = rootGetters['routing/activeCollectionType'] as CollectionType;
			const webLocale = rootState.language.webLocale;
			commit('SET_FILTER_VALUE', { webLocale, collectionType: activeCollectionType, filterName, filterValue });
		});

		if (updateRouteState) {
			dispatch('updateRouterState');
		}
	},

	resetPremiumFilters: async ({ dispatch }) => {
		const premiumFilterNames: FilterName[] = [
			'min_runtime',
			'max_runtime',
			'production_countries',
			'exclude_production_countries',
			'sort_by',
			'scoring_filter_types',
		];

		const collectionTypes = [CollectionType.NEW, CollectionType.POPULAR];

		const resetFilter = async (filterName: FilterName, collectionType?: CollectionType) => {
			await dispatch('setFilterValue', {
				filterName,
				filterValue: getDefaultFilterCollection()[filterName],
				collectionType,
				updateRouteState: false,
			});
		};

		for (const collectionType of collectionTypes) {
			for (const filterName of premiumFilterNames) {
				await resetFilter(filterName, collectionType);
			}
		}
	},

	reset: async (
		{ getters, dispatch, commit, rootState, rootGetters },
		payload: {
			collectionTypeOverride?: CollectionType;
			updateRouterState?: boolean;
			keepProvidersState: boolean;
			keepContentTypeState: boolean;
		}
	) => {
		// INP case: artificially give the JS thread time to breathe by adding a re-render.
		await new Promise(resolve => setTimeout(resolve, 0));

		const webLocale = rootState.language.webLocale;
		let collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;

		if (payload?.collectionTypeOverride) {
			collectionType = payload.collectionTypeOverride;
		}

		const filterState = {
			...getDefaultFilterCollection(),
			...(payload?.keepContentTypeState
				? {
						content_types: getters.currentFilters.content_types,
				  }
				: {}),
			...(payload?.keepProvidersState
				? {
						providers: getters.currentFilters.providers,
						providers_type: getters.currentFilters.providers_type,
				  }
				: {}),
		};

		commit('SET_FILTERS', { webLocale, collectionType, filterState });
		dispatch('updateRouterState', { collectionType });

		TrackingHelper.trackEvent('userinteraction', {
			action: 'reset',
		});
		//POPULAR_EXP
		trackPopularExp({ action: 'click', label: 'reset' });
		//POPULAR_EXP
	},

	toggle: async (
		{ getters, dispatch, rootState },
		{
			filterName,
			value,
			selectedState,
			trackEvent = true,
			isPremiumFilter = false,
			updateRouteState = true,
			// temporary solution until we refactor this logic
			userSelectedPackages,
		}: {
			filterName: FilterName;
			value: any;
			selectedState?: 1 | 0;
			trackEvent?: boolean;
			isPremiumFilter?: boolean;
			updateRouteState?: boolean;
			userSelectedPackages?: string[];
		}
	) => {
		// INP case: artificially give the JS thread time to breathe by adding a re-render.
		await new Promise(resolve => setTimeout(resolve, 0));

		const userProviders = userSelectedPackages || rootState.user.settings.providers || [];
		const oldValues = getters.currentFilter(filterName);
		let filterValue = Array.isArray(oldValues) ? oldValues.slice(0) : [];

		// in case 'all' as been toggled, ignore it
		if (value === null && oldValues.length === 0) {
			return;
		}
		switch (filterName) {
			// only can have 1 value selected at once
			case 'content_types':
			case 'subgenres':
			case 'presentation_types':
			case 'inner_tab':
				const oldValueIsUnset = !oldValues || oldValues?.length === 0;
				const oldValueIsDifferent = oldValues?.length === 1 && oldValues[0] !== value;
				const newValueIsValid = value !== null;

				if (oldValueIsUnset || (oldValueIsDifferent && newValueIsValid)) {
					filterValue = [value];
				} else {
					filterValue = [];
				}

				break;
			default:
				if (value === null) {
					filterValue = [];
				} else if (filterValue.includes(value)) {
					// add or remove the value from the arrays
					filterValue.splice(filterValue.indexOf(value), 1);
				} else {
					filterValue.push(value);
				}
				break;
		}
		if (filterValue.length === 0 || arrayOfStringsAreEquals(filterValue, userProviders)) {
			dispatch('resetFilter', { filterName, updateRouteState });
		} else {
			dispatch('setFilterValue', { filterName, filterValue, updateRouteState });
		}
		if (trackEvent) {
			TrackingHelper.trackFilterToggleEvent(
				{
					label: filterName,
					property: value ? value : 'all',
					value: selectedState,
				},
				isPremiumFilter
			);
			//POPULAR_EXP
			if (!['content_types', 'providers'].includes(filterName)) {
				trackPopularExp({ action: 'click', label: 'filter_toggled' });
			}
			//POPULAR_EXP
		}
	},

	/**
	 * sets the filter and updates the state.
	 */
	setFilterValue: (
		{ dispatch, rootState, rootGetters, commit },
		{
			filterName,
			filterValue,
			collectionType,
			updateRouteState = true,
		}: {
			filterName: FilterName;
			filterValue: any;
			collectionType?: CollectionType;
			updateRouteState?: boolean;
		}
	) => {
		if (!collectionType) {
			collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;
		}
		const webLocale = rootState.language.webLocale;
		commit('SET_FILTER_VALUE', { webLocale, collectionType, filterName, filterValue });
		updateRouteState && dispatch('updateRouterState');
	},

	setFilterValueForCollectionTypes: (
		{ rootState, commit },
		{
			filterName,
			filterValue,
			collectionTypes,
		}: {
			filterName: FilterName;
			filterValue: any;
			collectionTypes: CollectionType[];
		}
	) => {
		const webLocale = rootState.language.webLocale;

		collectionTypes.forEach(collectionType =>
			commit('SET_FILTER_VALUE', { webLocale, collectionType, filterName, filterValue })
		);
	},

	updateRouterState: (
		{ state, rootState, rootGetters }: { state: FilterState; rootState: any; rootGetters: any },
		payload: {
			collectionType?: CollectionType;
			routeQuery?: Route['query'];
		}
	) => {
		const activeNewPageType = getCollectionTypeByNewPageType(rootState.routing.activeRoute?.meta?.newPageType);
		const { webLocale, collectionType, routeQuery } = {
			webLocale: rootState.language.webLocale as WebLocale,
			collectionType: rootGetters['routing/activeCollectionType'] as CollectionType,
			routeQuery: rootState.routing.activeRoute.query,
			...payload,
		};
		// watchlist always uses it's own set of filters
		if (collectionType !== 'titlelist') {
			updateState(
				getVm().$router,
				undefined,
				activeNewPageType && activeNewPageType !== CollectionType.NEW ? activeNewPageType : collectionType, // we don't keep newPageType in any filters, so we need to differentiate here between NewPageType and CollectionType
				state[webLocale][collectionType],
				rootGetters['constant/providersByShortName'],
				rootGetters['constant/genresByShortName'],
				routeQuery,
				rootGetters['constant/subgenresList']
			);
		}
	},

	/**
	 * in case a language has been changed, we might need to re-initialize the state that has been initialized from `languageStore.state.webLocale`.
	 */
	initFiltersPerLocale: ({ state, commit }, webLocale: WebLocale) => {
		commit('INIT_STATE', { webLocale, newState: fillCountryFilters(state[webLocale]) });
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {
	SET_FILTERS(
		state,
		payload: { webLocale: WebLocale; collectionType: CollectionType; filterState: FilterCollection }
	) {
		const { collectionType, filterState, webLocale } = payload;
		state[webLocale][collectionType] = {
			...state[webLocale][collectionType],
			...filterState,
		};
	},
	SET_FILTER_VALUE: function (
		state,
		{
			webLocale,
			collectionType,
			filterName,
			filterValue,
		}: {
			webLocale: WebLocale;
			collectionType: CollectionType | CollectionType[];
			filterName: FilterName;
			filterValue: any;
		}
	) {
		const collectionTypes = Array.isArray(collectionType) ? collectionType : [collectionType];
		for (const collectionType of collectionTypes) {
			state[webLocale][collectionType] = {
				...state[webLocale][collectionType],
				[filterName]: filterValue,
			};
		}
	},

	INIT_STATE: function (state, { webLocale, newState }: { webLocale: WebLocale; newState: any }) {
		Vue.set(state, webLocale, newState);
	},
};

function isRandomSort(sortBy: string, collectionType: CollectionType): boolean {
	return (
		[
			CollectionType.POPULAR,
			CollectionType.TV_SHOW_TRACKING,
			CollectionType.MY_LISTS,
			CollectionType.PUBLIC_LISTS,
		].includes(collectionType) && sortBy === TitleOrder.RANDOM
	);
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
