import { getVm } from '@/helpers/vm-helper';
import QtvCtaExp, { QtvCtaExpVariants, QtvCtaExpName } from '.';
import { ExperimentTrackEventOptions } from '../Core/types';

export const trackQtvExpCta = (TrackingOptions: ExperimentTrackEventOptions<QtvCtaExpVariants>, lastRoute = false) => {
	const vm = getVm();
	const activeVariant = vm?.$store.getters['experiment/activeVariantsWithControlGroup'][QtvCtaExpName] ?? null;
	const lastRouteName = vm?.$store.state.routing.lastRoute?.name;
	const routeName = lastRoute ? lastRouteName : vm?.$route.name;
	if (activeVariant && ['app.detail.movies', 'app.detail.show'].includes(routeName)) {
		QtvCtaExp.trackEvent({
			property: 'qtv_cta',
			...TrackingOptions,
		});
	}
};
