import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from './WatchNowOffer.fragment';
export type SponsoredAdFragment = {
	__typename?: 'SponsoredRecommendationAd';
	bidId: string;
	holdoutGroup: boolean;
	campaign?: {
		__typename?: 'SponsoredRecommendationCampaign';
		name: string;
		countdownTimer?: any | null;
		creativeType?: Types.CreativeType | null;
		disclaimerText?: string | null;
		hideDetailPageButton: boolean;
		hideImdbScore?: boolean | null;
		hideJwScore?: boolean | null;
		hideRatings: boolean;
		hideContent?: boolean | null;
		posterOverride?: string | null;
		promotionalImageUrl?: string | null;
		promotionalTitle?: string | null;
		promotionalText?: string | null;
		promotionalProviderLogo?: string | null;
		promotionalProviderWideLogo?: string | null;
		watchNowLabel?: string | null;
		backgroundImages?: Array<{
			__typename?: 'BackgroundImage';
			imageURL: string;
			size: Types.BackgroundImageSize;
		} | null> | null;
		externalTrackers: Array<{ __typename?: 'ExternalTracker'; type: Types.ExternalTrackerType; data: string }>;
		promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
		watchNowOffer: {
			__typename?: 'Offer';
			id: string;
			standardWebURL?: string | null;
			streamUrl?: string | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			lastChangeRetailPriceValue?: number | null;
			currency?: string | null;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			availableTo?: any | null;
			dateCreated?: any | null;
			package: {
				__typename?: 'Package';
				id: string;
				icon: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				iconWide: string;
				hasRectangularIcon: boolean;
			};
		};
		nodeOverrides: Array<{
			__typename?: 'NodeOverride';
			nodeId: string;
			promotionalImageUrl?: string | null;
			watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
		}>;
		node:
			| { __typename: 'Article'; nodeId: string }
			| { __typename: 'Author'; nodeId: string }
			| { __typename: 'Bundle'; nodeId: string }
			| { __typename: 'Device'; nodeId: string }
			| { __typename: 'Episode'; nodeId: string }
			| {
					__typename: 'GenericTitleList';
					id: string;
					type: Types.GenericTitleListType;
					nodeId: string;
					followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any; name: string } | null;
					content: {
						__typename?: 'GenericTitleListContent';
						name: string;
						visibility: Types.GenericTitleListVisibility;
					};
					titles: {
						__typename?: 'GenericTitleListConnection';
						totalCount: number;
						edges?: Array<{
							__typename?: 'GenericTitleListEdge';
							cursor: string;
							node:
								| {
										__typename?: 'Movie';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										content: {
											__typename?: 'MovieContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbVotes?: number | null;
												imdbScore?: number | null;
												tomatoMeter?: number | null;
												certifiedFresh?: boolean | null;
												jwRating?: number | null;
											};
										};
								  }
								| {
										__typename?: 'Season';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										content: {
											__typename?: 'SeasonContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbVotes?: number | null;
												imdbScore?: number | null;
												tomatoMeter?: number | null;
												certifiedFresh?: boolean | null;
												jwRating?: number | null;
											};
										};
								  }
								| {
										__typename?: 'Show';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										content: {
											__typename?: 'ShowContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbVotes?: number | null;
												imdbScore?: number | null;
												tomatoMeter?: number | null;
												certifiedFresh?: boolean | null;
												jwRating?: number | null;
											};
										};
								  };
						}> | null;
					};
			  }
			| { __typename: 'Genre'; nodeId: string }
			| {
					__typename: 'Movie';
					objectId: number;
					objectType: Types.ObjectType;
					nodeId: string;
					content: {
						__typename?: 'MovieContent';
						fullPath: string;
						posterUrl?: string | null;
						title: string;
						originalReleaseYear?: number | null;
						isReleased: boolean;
						scoring: { __typename?: 'Scoring'; imdbScore?: number | null; jwRating?: number | null };
						genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					};
					offers: Array<{
						__typename?: 'Offer';
						monetizationType: Types.MonetizationType;
						presentationType: Types.PresentationType;
						id: string;
						package: { __typename?: 'Package'; id: string; packageId: number };
					}>;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| { __typename: 'MultiStepSportEvent'; nodeId: string }
			| { __typename: 'Offer'; nodeId: string }
			| { __typename: 'Package'; nodeId: string }
			| { __typename: 'Page'; nodeId: string }
			| { __typename: 'Person'; nodeId: string }
			| {
					__typename: 'Season';
					objectId: number;
					objectType: Types.ObjectType;
					nodeId: string;
					content: {
						__typename?: 'SeasonContent';
						fullPath: string;
						posterUrl?: string | null;
						title: string;
						originalReleaseYear?: number | null;
						isReleased: boolean;
						seasonNumber: number;
						scoring: { __typename?: 'Scoring'; imdbScore?: number | null; jwRating?: number | null };
						genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					};
					offers: Array<{
						__typename?: 'Offer';
						monetizationType: Types.MonetizationType;
						presentationType: Types.PresentationType;
						id: string;
						package: { __typename?: 'Package'; id: string; packageId: number };
					}>;
					show: {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'ShowContent'; originalTitle: string };
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					};
			  }
			| {
					__typename: 'Show';
					objectId: number;
					objectType: Types.ObjectType;
					nodeId: string;
					content: {
						__typename?: 'ShowContent';
						fullPath: string;
						posterUrl?: string | null;
						title: string;
						originalReleaseYear?: number | null;
						isReleased: boolean;
						scoring: { __typename?: 'Scoring'; imdbScore?: number | null; jwRating?: number | null };
						genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					};
					offers: Array<{
						__typename?: 'Offer';
						monetizationType: Types.MonetizationType;
						presentationType: Types.PresentationType;
						id: string;
						package: { __typename?: 'Package'; id: string; packageId: number };
					}>;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
			  }
			| { __typename: 'SingleStepSportEvent'; nodeId: string }
			| { __typename: 'SportCompetitionV2'; nodeId: string }
			| { __typename: 'SportCompetitorV2'; nodeId: string }
			| { __typename: 'Url'; nodeId: string };
	} | null;
};

export const SponsoredAdFragmentDoc = gql`
	fragment SponsoredAd on SponsoredRecommendationAd {
		bidId
		holdoutGroup
		campaign {
			name
			backgroundImages {
				imageURL
				size
			}
			countdownTimer
			creativeType
			disclaimerText
			externalTrackers {
				type
				data
			}
			hideDetailPageButton
			hideImdbScore
			hideJwScore
			hideRatings
			hideContent
			posterOverride
			promotionalImageUrl
			promotionalVideo {
				url
			}
			promotionalTitle
			promotionalText
			promotionalProviderLogo
			promotionalProviderWideLogo
			watchNowLabel
			watchNowOffer {
				...WatchNowOffer
			}
			nodeOverrides {
				nodeId
				promotionalImageUrl
				watchNowOffer {
					standardWebURL
				}
			}
			node {
				nodeId: id
				__typename
				... on MovieOrShowOrSeason {
					content(country: $country, language: $language) {
						fullPath
						posterUrl
						title
						originalReleaseYear
						scoring {
							imdbScore
							jwRating
						}
						genres {
							shortName
							translation(language: $language)
						}
						externalIds {
							imdbId
						}
						backdrops(format: $format, profile: $backdropProfile) {
							backdropUrl
						}
						isReleased
					}
					objectId
					objectType
					offers(country: $country, platform: $platform) {
						monetizationType
						presentationType
						package {
							id
							packageId
						}
						id
					}
				}
				... on MovieOrShow {
					watchlistEntryV2 {
						createdAt
					}
				}
				... on Show {
					seenState(country: $country) {
						seenEpisodeCount
					}
				}
				... on Season {
					content(country: $country, language: $language) {
						seasonNumber
					}
					show {
						__typename
						id
						objectId
						objectType
						content(country: $country, language: $language) {
							originalTitle
						}
						watchlistEntryV2 {
							createdAt
						}
					}
				}
				... on GenericTitleList {
					followedlistEntry {
						createdAt
						name
					}
					id
					type
					content(country: $country, language: $language) {
						name
						visibility
					}
					titles(country: $country, first: 40) {
						totalCount
						edges {
							cursor
							node: nodeV2 {
								content(country: $country, language: $language) {
									fullPath
									posterUrl
									title
									originalReleaseYear
									scoring {
										imdbVotes
										imdbScore
										tomatoMeter
										certifiedFresh
										jwRating
									}
									isReleased
								}
								id
								objectId
								objectType
							}
						}
					}
				}
			}
		}
	}
	${WatchNowOfferFragmentDoc}
`;
