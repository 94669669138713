var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('picture',{staticClass:"picture-element"},[(!_setup.isPng)?[_c('source',{attrs:{"type":"image/avif","media":"(min-width: 0px)","srcset":_vm.lazy ? '' : _setup.avifImageUrl,"data-srcset":_vm.lazy ? _setup.avifImageUrl : ''}}),_c('source',{attrs:{"type":"image/webp","media":"(min-width: 0px)","srcset":_vm.lazy ? '' : _setup.webpImageUrl,"data-srcset":_vm.lazy ? _setup.webpImageUrl : ''}})]:_vm._e(),_c('img',{staticClass:"provider-icon",class:[
			{
				lazyload: _vm.lazy,
				wide: _setup.isWideIcon,
				square: !_setup.isWideIcon,
			},
			_vm.size,
		],attrs:{"alt":_setup.providerClearName,"title":_setup.providerClearName,"data-sizes":_vm.lazy ? 'auto' : undefined,"src":_vm.lazy ? _setup.transparentBase64 : _setup.imageUrl,"data-src":_vm.lazy ? _setup.imageUrl : undefined,"data-testid":_setup.imageUrl ? 'provider-icon-override' : 'provider-icon',"data-providerid":_vm.providerId},on:{"click":function($event){return _vm.$emit('click')}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }